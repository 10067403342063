import { NO_VALIDATOR_FOR_THIS_FILED, RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { StepError } from '~/state/mainAppState';
import { RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { regexps } from '~/utils/tools';

export class ValidateRentalApplicationInfoUseCase extends MagicUseCase {
  private validateFirstName = () => {
    const firstName = this.getState().user.rentalApplication.application?.firstName;

    if (!firstName || !regexps.name.test(firstName)) {
      return RentalApplicationErrorCode.InvalidFirstName;
    }
  };

  private validateLastName = () => {
    const lastName = this.getState().user.rentalApplication.application?.lastName;

    if (!lastName || !regexps.name.test(lastName)) {
      return RentalApplicationErrorCode.InvalidLastName;
    }
  };

  private validateEmail = () => {
    const email = this.getState().user.rentalApplication.application?.email || '';

    if (!regexps.email.test(email)) {
      return RentalApplicationErrorCode.InvalidEmail;
    }
  };

  private validatePhone = () => {
    const phoneNumber = this.getState().user.rentalApplication.application?.phone || '';
    const isValid = regexps.phone.test(phoneNumber);

    if (!isValid) {
      return RentalApplicationErrorCode.InvalidPhone;
    }
  };

  private validateSSN = () => {
    const ssn = this.getState().user.rentalApplication.application?.ssn || '';
    const isValid = regexps.ssn.test(ssn);

    if (!isValid) {
      return RentalApplicationErrorCode.InvalidSSN;
    }
  };

  private validateDateOfBirth = () => {
    let errorCode;
    const dateOfBirth = this.getState().user.rentalApplication.application?.dateOfBirth || '';
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (!regexps.date.test(dateOfBirth)) {
      errorCode = RentalApplicationErrorCode.InvalidDateOfBirth;
    } else if (age < 18) {
      errorCode = RentalApplicationErrorCode.InvalidDateOfBirthLesser;
    } else if (age > 125) {
      errorCode = RentalApplicationErrorCode.InvalidDateOfBirthGreater;
    }

    if (errorCode) {
      return errorCode;
    }
  };

  private validateDesiredMoveInDate = () => {
    const desiredMoveInDate = this.getState().user.rentalApplication.application?.desiredMoveInDate || '';

    if (!regexps.date.test(desiredMoveInDate)) {
      return RentalApplicationErrorCode.InvalidDesiredMoveInDate;
    }
  };

  private validateComment = () => {
    const comment = this.getState().user.rentalApplication.application?.comments || [];

    if (comment.length && comment.some((c) => c.comment.length > 500)) {
      return RentalApplicationErrorCode.InvalidComment;
    }
  };

  private rules: Partial<Record<string, { validator: () => RentalApplicationErrorCode | undefined }>> = {
    firstName: { validator: this.validateFirstName },
    lastName: { validator: this.validateLastName },
    email: { validator: this.validateEmail },
    phone: { validator: this.validatePhone },
    ssn: { validator: this.validateSSN },
    dateOfBirth: { validator: this.validateDateOfBirth },
    desiredMoveInDate: { validator: this.validateDesiredMoveInDate },
    comment: { validator: this.validateComment },
  };

  protected async runLogic(fieldName: string) {
    const { t } = useLocalization();
    const application = this.getState().user.rentalApplication.application;

    if (!application) {
      throw new Error(t(RENTAL_APPLICATION_NOT_FOUND));
    }

    this.getState().user.rentalApplication.errors = { ...this.getState().user.rentalApplication.errors };

    if (fieldName) {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.APPLICANT_INFORMATION] = {
        ...this.getState().user.rentalApplication.errors[RentalApplicationSteps.APPLICANT_INFORMATION],
      };

      const validator = this.rules[fieldName]?.validator;
      const stepError = this.getState().user.rentalApplication.errors[RentalApplicationSteps.APPLICANT_INFORMATION] as StepError;

      if (!validator) {
        throw new Error(t(NO_VALIDATOR_FOR_THIS_FILED, { fieldName }));
      }

      stepError[fieldName] = validator();
    } else {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.APPLICANT_INFORMATION] = {};

      Object.keys(this.rules).forEach((key) => {
        const stepError = this.getState().user.rentalApplication.errors[RentalApplicationSteps.APPLICANT_INFORMATION] as StepError;
        stepError[key] = this.rules[key]?.validator();
      });
    }
  }
}
